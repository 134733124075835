.create-run-progress-stepper {
  padding: 0 25px;
}

.create-run-progress-step {
  display: flex;
  align-items: flex-start;
  position: relative;
  height: 88px;

  &-inactive {
    color: #A8A8A8 !important;
  }

  &-error-color {
    color: #DE4C3B;
  }

  &-refresh-suggestion {
    height: 148px !important;
  }

  &.create-run-progress-step-complete,
  &.create-run-progress-step-active,
  &.create-run-progress-step-error {
    height: 128px;
  }

  &.create-run-progress-step-complete::before,
  &.create-run-progress-step-error::before,
  &.create-run-progress-step-active::before {
    height: 128px;
  }

  &.create-run-progress-step-complete::before {
    background: #3FBA03;
  }

  &.create-run-progress-step-complete .create-run-progress-step-circle,
  &.create-run-progress-step-active .create-run-progress-step-circle {
    &::before {
      height: 100px;
    }
  }

  &.create-run-progress-step-refresh-suggestion .create-run-progress-step-circle {
    &::before {
      height: 120px;
    }
  }

  &.create-run-progress-step-error .create-run-progress-step-circle {
    border-color: #DE4C3B;

    &::before {
      background: #DE4C3B;
    }
  }

  &.create-run-progress-step-inactive .create-run-progress-step-circle {
    border-color: #A8A8A8;

    &::before {
      background: #A8A8A8;
    }
  }

  &.create-run-progress-step-complete .create-run-progress-step-circle {
    border-color: #3FBA03;

    &::before {
      background: #3FBA03;
    }
  }

  &.create-run-progress-step-error .create-run-progress-step-circle {
    border-color: #DE4C3B;

    &::before {
      border-left: 2px dashed #DE4C3B; /* Added dashed border for error */
      background: unset;
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      height: 75px;
      transform: translateX(-50%);
      z-index: -1;
    }
  }

  .create-run-progress-step-circle {
    width: 30px;
    height: 30px;
    min-width: 30px;
    border-radius: 50%;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    z-index: 1;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 1px;
      height: 60px;
      background: #fff;
      z-index: -1;
    }
  }

  &:last-child .create-run-progress-step-circle::before {
    content: none;
  }

  .create-run-progress-step-details {
    flex-grow: 1;

    &-title {
      font-size: 18px;
      font-weight: 400;
      color: #fff;
    }

    &-status-text {
      font-size: 18px;
      font-weight: 300;

      &.create-run-progress-step-complete {
        color: #A8A8A8 !important;
      }

      &-divider {
        width: 35%;
        height: 1px;
        background: #ccc;
        margin: 5px 0;
      }
    }

    &-status-description {
      font-size: 13px;
      font-weight: 300;

      &.create-run-progress-step-complete {
        color: #A8A8A8 !important;
      }
    }

    &-status-refresh-suggestion-text {
      animation-name: fadeIn;
      animation-duration: .4s;
      animation-fill-mode: forwards;
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 500;
      color: honeydew;
      margin-top: 5px;
    }

    &-status-refresh-suggestion-btn {
      font-size: 12px;
      padding: 0;
      color: rgba(135, 206, 250, 0.99);
      vertical-align: baseline;

      &:focus {
        box-shadow: unset !important;
      }

      &:hover {
        color: rgba(135, 206, 250, 0.8);
      }
    }
  }
}

.create-run-progress-step-inactive .create-run-progress-step-details-title {
  color: #A8A8A8 !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
